import EmptyView from '@/components/EmptyView'
export default [
  {
    path: '/temple',
    redirect: '/',
    component: EmptyView,
    meta: {
      name: '南普陀寺',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    },
    children: [
      {
        path: '',
        name: 'temple',
        component: () => import('@/views/temple'),
        meta: {
          name: '南普陀寺',
          noAddInRouteList: true,
          headerVisible: true,
          footerVisible: true,
          breadVisible: false,
          keepAlive: false
        }
      },
      {
        path: 'abbot',
        name: 'abbot',
        component: () =>
          import('@/views/temple/detail/abbot'),
        meta: {
          name: '方丈',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '方丈',
          keepAlive: false
        }
      },
      {
        path: 'priests',
        name: 'priests',
        component: () =>
          import('@/views/temple/detail/priests'),
        meta: {
          name: '本寺僧伽',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '本寺僧伽',
          keepAlive: false
        }
      },
      {
        path: 'service',
        name: 'templeService',
        component: () =>
          import('@/views/temple/detail/service'),
        meta: {
          name: '寺院服务',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '寺院服务',
          keepAlive: false
        }
      },
      {
        path: 'introduce',
        name: 'introduce',
        component: () =>
          import('@/views/temple/detail/introduce'),
        meta: {
          name: '寺院介绍',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '寺院介绍',
          keepAlive: false
        }
      },
      {
        path: 'fortune',
        name: 'fortune',
        component: () =>
          import('@/views/temple/detail/fortune'),
        meta: {
          name: '每日福签',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '每日福签',
          keepAlive: false
        }
      },
      {
        path: 'book',
        name: 'book',
        component: () =>
          import('@/views/temple/detail/book'),
        meta: {
          name: '经书结缘',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '经书结缘',
          keepAlive: false
        }
      },
      {
        path: 'guide',
        name: 'guide',
        component: () =>
          import('@/views/temple/detail/guide'),
        meta: {
          name: '寺院导览',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '寺院导览',
          keepAlive: false
        }
      }
    ]
  }
]

<template>
  <div class="container">
    <HeaderView v-if="$route.meta.headerVisible" />
    <div class="main">
      <ContentView />
      <FooterView v-if="$route.meta.footerVisible" />
    </div>
    <el-backtop :bottom="isMobile && isMobile.length ? 100 : 50" :right="50"></el-backtop>
  </div>
</template>

<script>
import router from '@/router'
import { selfUrl } from '@/api/config'
import common from '@/api/module/common'
import HeaderView from './components/header'
import ContentView from './components/content'
import FooterView from './components/footer'
import { scrollPageTop } from '@/utils/common'

const metaInfo = {
  meta: [],
  link: []
}
router
  .getRoutes()
  .filter(
    r => r.path && r.path.includes('/') && r.path !== '/403'
  )
  .forEach(item => {
    metaInfo.meta.push({
      name: item.meta.name,
      content: item.parent
        ? item.parent.meta.name
        : '厦门南普陀寺'
    })
    metaInfo.link.push({
      rel: 'asstes',
      href: `${selfUrl}/#${item.path}`
    })
  })
export default {
  metaInfo,
  name: 'LayoutsView', //布局容器
  components: {
    HeaderView,
    ContentView,
    FooterView
  },
  watch: {
    $route({ path, query, meta }, { path: oldPath }) {
      if (path !== oldPath) {
        common.commonVisitor(document.title)
      }
      scrollPageTop()
      const hasRoute = this.$router
        .getRoutes()
        .some(item => item.path === path)
      !meta.noStorage &&
        hasRoute &&
        localStorage.setItem(
          'routeParams',
          JSON.stringify({ path, query })
        )
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  :deep(.el-backtop) {
    color: @main-color-A45E44;
    &:hover {
      background-color: @main-color-F9F9F5;
    }
  }
}
</style>

<template>
  <div
    class="my-shadow"
    :style="{ opacity, backgroundColor }"
  ></div>
</template>

<script>
export default {
  name: 'MyShadow', //阴影组件
  props: {
    opacity: {
      //透明度 默认0.5
      type: Number,
      default: 0.5
    },
    backgroundColor: {
      //阴影颜色 默认黑色
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style lang="less" scoped>
.my-shadow {
  // cursor:pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
}
</style>

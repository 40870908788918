<template>
  <div class="header_view">
    <section class="first-level-nav">
      <nav>
        <ul class="left HBKX">
          <li
            :class="
              $route.path === path ||
              $route.path.includes(path)
                ? 'is-active'
                : ''
            "
            @click="
              $router.push(path)
              showSecondLevelNav = false
            "
            v-for="(
              { label, path, children }, index
            ) of headerNavData.left"
            :key="index"
            @mouseenter="
              showSecondLevelNavBox(label, children, $event)
            "
          >
            <p>
              {{ label }}
            </p>
            <img
              v-if="
                $route.path === path ||
                $route.path.includes(path)
              "
              class="flower"
              src="https://f.nanputuo.com/nanputuo/img/home/莲花.png"
              alt="flower"
            />
          </li>
        </ul>
        <div class="logo">
          <img
            class="brown-bg"
            src="https://f.nanputuo.com/nanputuo/img/home/logobg.png"
            alt="logobg"
          />
          <img
            class="white-logo"
            src="https://f.nanputuo.com/nanputuo/img/home/logo.png"
            alt="logo"
          />
        </div>
        <ul class="right HBKX">
          <li
            :class="
              $route.path === path ||
              $route.path.includes(path)
                ? 'is-active'
                : ''
            "
            @click="
              $router.push(path)
              showSecondLevelNav = false
            "
            v-for="(
              { label, path, children }, index
            ) of headerNavData.right"
            :key="index"
            @mouseenter="
              showSecondLevelNavBox(label, children, $event)
            "
          >
            <p>{{ label }}</p>
            <img
              v-if="
                $route.path === path ||
                $route.path.includes(path)
              "
              class="flower"
              src="https://f.nanputuo.com/nanputuo/img/home/莲花.png"
              alt="flower"
            />
          </li>
        </ul>
      </nav>
      <div class="user-setting SourceHanSerifCN-Regular">
        <aside>
          <button
            v-if="!token"
            @click="userLogin"
            class="SourceHanSerifCN-Regular"
          >
            用户登录
          </button>
          <el-dropdown
            ref="myDropdown"
            v-else
            @command="userCenterCommand"
            placement="bottom"
          >
            <el-button>
              个人中心<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </el-button>
            <el-dropdown-menu
              slot="dropdown"
              :append-to-body="false"
            >
              <el-dropdown-item command="userCenter"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item command="logOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </aside>
        <section>
          <div
            class="icon"
            @click="loginOrUserCenter"
            @mouseenter="token && $refs.myDropdown.show()"
            @mouseleave="token && $refs.myDropdown.hide()"
          >
            <img
              src="https://f.nanputuo.com/nanputuo/img/home/登录或个人中心.png"
              alt="登录或个人中心"
            />
          </div>
          <div
            class="icon"
            @click="$router.push('/leaveMessage')"
          >
            <img
              src="https://f.nanputuo.com/nanputuo/img/home/留言.png"
              alt="留言"
            />
          </div>
          <div class="icon">
  <a href="https://en.nanputuo.com/" target="_blank">
    <img src="https://f.nanputuo.com/nanputuo/img/home/语言切换.png" alt="语言切换" />
  </a>
</div>

          <div
            class="icon"
            @click="$router.push('/search')"
          >
            <img
              src="https://f.nanputuo.com/nanputuo/img/home/搜索.png"
              alt="搜索"
            />
          </div>
        </section>
      </div>
    </section>
    <transition name="second-level-nav">
      <nav
        class="second-level-nav"
        :style="{
          paddingLeft: `${secondLevelNavPaddingLeft}px`
        }"
        v-if="showSecondLevelNav"
        @mouseleave="showSecondLevelNav = false"
      >
        <transition name="second-level-nav-ul">
          <ul
            v-if="showSecondLevelNavUl"
            ref="secondLevelNav"
            class="HBKX"
          >
            <li
              :class="
                $route.path.includes(path)
                  ? 'is-active'
                  : ''
              "
              v-for="(
                { label, path, id }, index
              ) of secondLevelNavList"
              :key="index"
              @click="goSecondPage(id, path)"
            >
              <p>
                <span
                  v-for="(n, nIndex) of label"
                  :key="nIndex"
                >
                  {{ n }}
                </span>
              </p>
            </li>
          </ul>
        </transition>
      </nav>
    </transition>
    <MyCarousel
      :height="`${$route.path === '/home' ? 600 : 460}px`"
      :diyArrow="false"
      autoplay
      :carouselImgList="carouselImgList"
      :showName="$route.path === '/home' ? true : false"
    />
  </div>
</template>

<script>
import apply from '@/api/module/apply'
import common from '@/api/module/common'
import member from '@/api/module/member'
import { $url, $logOut } from '@/api/config'
import { mapState, mapMutations } from 'vuex'
import MyCarousel from '@/components/MyCarousel'

export default {
  name: 'HeaderView', //页头
  components: {
    MyCarousel
  },
  data() {
    return {
      carouselImgList: [
        {
          img: 'https://f.nanputuo.com/nanputuo/img/home/banner.png'
        }
      ], //轮播图列表
      headerNavData: {
        //头部导航数据
        left: [
          {
            label: '首页',
            path: '/home'
          }
        ],
        right: []
      },
      showSecondLevelNav: false, // 是否展示二级导航
      showSecondLevelNavUl: false, // 是否展示二级导航里的Ul
      secondLevelNavPaddingLeft: null, // 二级导航内边距 动态设置
      secondLevelNavList: [] // 二级导航数组
    }
  },
  computed: {
    ...mapState('authAbout', ['token']) //token
  },
  methods: {
    // 往vuex设置token
    ...mapMutations('authAbout', {
      SET_USER_TOKEN: 'SET_USER_TOKEN'
    }),
    // 跳转登录页面
    userLogin() {
      window.location.href = $url
      // this.$router.push('/login')
    },
    // 未登录时点击用户图标跳转登录页面 登录后点击不做处理
    loginOrUserCenter() {
      if (!this.token) return (window.location.href = $url)
      // if (!this.token) return this.$router.push('/login')
    },
    // 个人中心下拉项点击
    async userCenterCommand(action) {
      switch (action) {
        case 'userCenter': //个人中心
          this.$router.push('/userCenter')
          break
        case 'logOut': //退出登录
          try {
            const { code, msg } = await member.loginOut()
            if (!code) {
              this.$message.success(msg)
              this.SET_USER_TOKEN('')
              localStorage.removeItem('token')
              localStorage.removeItem('routeParams')
              // this.$router.push('/login')
              const $a = document.createElement('a')
              document.body.appendChild($a)
              $a.style.display = 'none'
              $a.href = 'javascript:void(0);'
              $a.onclick = () => {
                window.location.href = $logOut
              }
              $a.click()
              document.body.removeChild($a)
            }
          } catch (error) {
            console.error(error || '请求失败')
          }
          break
      }
    },
    // 显示二级导航函数
    showSecondLevelNavBox(
      label,
      children,
      { target: { offsetLeft } }
    ) {
      if (label === '首页' || label === '政策法规')
        return (this.showSecondLevelNav = false)
      this.showSecondLevelNav = true
      this.showSecondLevelNavUl = false
      this.$nextTick(() => {
        this.showSecondLevelNavUl = true
      })
      this.secondLevelNavPaddingLeft = offsetLeft
      this.secondLevelNavList = children
    },
    //获取二级功能背景图片
    async getSecondFunctionImg(id) {
      try {
        const {
          data: { imgUrl }
        } = await apply.secondFunctionBgImg(id)
        if (imgUrl) this.carouselImgList = [{ img: imgUrl }]
        else
          this.carouselImgList = [
            {
              img: 'https://f.nanputuo.com/nanputuo/img/home/banner.png'
            }
          ]
      } catch (error) {
        console.error(error || '请求失败')
      }
    },
    // 跳转二级页面 调二级功能背景图片接口
    goSecondPage(id, path) {
      this.$router.push({
        path,
        query:
          id === this.$route.query.id
            ? { ...this.$route.query, id }
            : { id } // 如果点击同一个id 则合并参数
      })
    },
    // 获取头部导航数据
    async getHeaderNav() {
      try {
        const {
          data: { parentIdList }
        } = await common.commonHeaderNav()
        this.headerNavData.left = [
          ...this.headerNavData.left,
          ...parentIdList.slice(0, 2)
        ]
        this.headerNavData.right = parentIdList.slice(2, 8)
      } catch (error) {
        console.error(error || '请求失败')
      }
    },
    init() {
      this.getHeaderNav()
      this.$bus.$on('headerCarousels', data => {
        if (data.length) {
          this.carouselImgList = data.map(item => {
            return {
              ...item,
              img:
                item.img && item.img.trim()
                  ? item.img
                  : 'https://f.nanputuo.com/nanputuo/img/home/banner.png'
            }
          })
        } else
          this.carouselImgList = [
            {
              img: 'https://f.nanputuo.com/nanputuo/img/home/banner.png'
            }
          ]
      })
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal?.path !== oldVal?.path) {
          if (newVal.query.id)
            this.getSecondFunctionImg(newVal.query.id)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header_view {
  position: relative;
  background-color: @main-color-FFFFFB;
  .first-level-nav {
    height: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    nav {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .logo {
        width: 350px;
        height: 230px;
        position: relative;
        z-index: 20;
        .brown-bg {
          width: 100%;
          height: 100%;
        }
        .white-logo {
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 6;
          width: 115px;
          height: 109px;
          margin-left: -57.5px;
          margin-top: -54.5px;
        }
      }
      .left,
      .right {
        flex: 1;
        display: flex;

        li {
          flex: 1;
          height: 160px;
          padding-left: 21px;
          border-left: 1px solid #d3c1ba;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          p {
            width: 23px;
            font-size: 24px;
            color: @font-color-695557;
            transition: all 0.2s;
          }
          &:hover,
          &.is-active {
            position: relative;
            p {
              color: @main-color-A45E44;
              font-size: 26px;
            }
            .flower {
              position: absolute;
              width: 27px;
              left: -14px;
              bottom: -24px;
            }
          }
        }
      }
      .left {
        padding-left: 200px;
        padding-right: 80px;
        justify-content: flex-end;
        li {
          max-width: 115px;
        }
      }
      .right {
        padding-left: 116px;
        justify-content: flex-start;
        li {
          max-width: 143px;
        }
      }
    }
    .user-setting {
      width: 148px;
      height: 160px;
      margin-top: 11px;
      display: flex;
      justify-content: space-between;
      padding-right: 23px;
      aside {
        button {
          width: 90px;
          min-width: 90px;
          height: 24px;
          line-height: 24px;
          border: 1px solid @main-color-A45E44;
          border-radius: 4px;
          background-color: unset;
          font-size: 14px;
          color: @main-color-A45E44;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
      }
      section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: unset;
          }
        }
      }
    }
  }
  .second-level-nav {
    width: 100%;
    height: 250px;
    position: absolute;
    left: 0;
    top: 200px;
    z-index: 16;
    cursor: pointer;
    background: rgba(249, 249, 245, 0.8);
    &.second-level-nav-enter,
    &.second-level-nav-leave-to {
      opacity: 0;
    }
    &.second-level-nav-enter-active,
    &.second-level-nav-leave-active {
      transition: all 0.5s;
    }
    &.second-level-nav-enter-to,
    &.second-level-nav-leave {
      opacity: 1;
    }
    ul {
      display: flex;
      &.second-level-nav-ul-enter,
      &.second-level-nav-leave-to {
        opacity: 0;
      }
      &.second-level-nav-ul-enter-active,
      &.second-level-nav-ul-leave-active {
        transition: all 0.6s;
      }
      &.second-level-nav-ul-enter-to,
      &.second-level-nav-ul-leave {
        opacity: 1;
      }
      li {
        width: 40px;
        height: 250px;
        font-size: 20px;
        background-color: @main-color-F9F9F5;
        color: @font-color-695557;
        text-align: center;
        border-right: 1px solid #d3c1ba;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        &:hover,
        &.is-active {
          color: @main-color-F9F9F5;
          background-color: @main-color-BFB2AA;
        }
        p {
          width: 36px;
          height: 134px;
          text-align: center;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>

export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home'),
    meta: {
      name: '首页',
      headerVisible: true,
      footerVisible: true,
      breadVisible: false,
      keepAlive: false,
      noStorage: true
    }
  }
]

import EmptyView from '@/components/EmptyView'
export default [
  {
    path: '/society',
    redirect: '/',
    component: EmptyView,
    meta: {
      name: '实业社',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    },
    children: [
      {
        path: '',
        name: 'society',
        component: () => import('@/views/society'),
        meta: {
          name: '实业社',
          noAddInRouteList: true,
          headerVisible: true,
          footerVisible: true,
          breadVisible: false,
          keepAlive: false
        }
      },
      {
        path: 'style',
        name: 'style',
        component: () =>
          import('@/views/society/detail/style'),
        meta: {
          name: '实业社风采',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '实业社风采',
          keepAlive: false
        }
      },
      {
        path: 'vegetable',
        name: 'vegetable',
        component: () =>
          import('@/views/society/detail/vegetable'),
        meta: {
          name: '素菜瑰宝',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '素菜瑰宝',
          keepAlive: false
        }
      },
      {
        path: 'vegetarian',
        name: 'vegetarian',
        component: () =>
          import('@/views/society/detail/vegetarian'),
        meta: {
          name: '素食飘香',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '素食飘香',
          keepAlive: false
        }
      },
      {
        path: 'creation',
        name: 'creation',
        component: () =>
          import('@/views/society/detail/creation'),
        meta: {
          name: '禅意文创',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '禅意文创',
          keepAlive: false
        }
      },
      {
        path: 'hall',
        name: 'hall',
        component: () =>
          import('@/views/society/detail/hall'),
        meta: {
          name: '妙湛药堂',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '妙湛药堂',
          keepAlive: false
        }
      },
      {
        path: 'course',
        name: 'course',
        component: () =>
          import('@/views/society/detail/course'),
        meta: {
          name: '百年历程',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '百年历程',
          keepAlive: false
        }
      }
    ]
  }
]

<template>
  <div class="footer_view">
    <section class="nav-box">
      <nav>
        <ul v-for="({ title, list }, index) of navData" :key="index">
          <li class="title HBKX">{{ title }}</li>
          <li class="SourceHanSerifCN-Light" v-for="({ title, address }, index) of list" :key="index" @click="openNewPage(address)">
            {{ title }}
          </li>
        </ul>
      </nav>
    </section>
    <footer>
      <div class="temple-info">
        <section class="left SourceHanSerifCN-Regular">
          <ul>
            <li>
              <img src="https://f.nanputuo.com/nanputuo/img/home/电话.png" alt="电话" />
              <p>电话：{{ contactUs.phone }}</p>
            </li>
            <li>
              <img src="https://f.nanputuo.com/nanputuo/image/2023-12/1702434126000_78727.png" alt="微信客服" />
              <p>微信客服：18150070282</p>
            </li>
            <li>
              <img src="https://f.nanputuo.com/nanputuo/img/home/邮编.png" alt="邮编" />
              <p>邮编：{{ contactUs.postalCode }}</p>
            </li>
            <li>
              <img src="https://f.nanputuo.com/nanputuo/img/home/地址.png" alt="地址" />
              <p>地址：{{ contactUs.address }}</p>
            </li>
            <li>
              <img src="https://f.nanputuo.com/nanputuo/img/home/友链.png" alt="友链" />
              <p>
                友情链接：
                <span v-for="(item, index) of friendUrlList" :key="index">
                  <a :href="item.address" target="_blank">{{
                    item.title
                  }}</a>
                </span>
              </p>
            </li>
          </ul>
        </section>
        <section class="right SourceHanSerifCN-Regular">
          <!--
          <ul>
            <el-popover placement="top" trigger="hover">
              <img class="qr-code" :src="contactUs.weChatQrCode" />
              <li slot="reference" @click="openNewPage(contactUs.weChatUrl)">
                <div class="icon-box">
                  <img src="https://f.nanputuo.com/nanputuo/img/home/微信.png" alt="微信" />
                </div>
                <p>微信公众号</p>
              </li>
            </el-popover>
            <el-popover placement="top" trigger="hover">
              <img class="qr-code" :src="contactUs.nptQrCode" />
              <li slot="reference" @click="openNewPage(contactUs.nptUrl)">
                <div class="icon-box">
                  <img src="https://f.nanputuo.com/nanputuo/img/home/未-微博.png" alt="未-微博" />
                </div>
                <p>南普陀寺</p>
              </li>
            </el-popover>
            <el-popover placement="top" trigger="hover">
              <img class="qr-code" :src="contactUs.lightShadowQrCode" />
              <li slot="reference" @click="
                  openNewPage(contactUs.lightShadowUrl)
                ">
                <div class="icon-box">
                  <img src="https://f.nanputuo.com/nanputuo/img/home/未-视频号.png" alt="未-视频号" />
                </div>
                <p>光影无相</p>
              </li>
            </el-popover>
          </ul>
          -->
        </section>
      </div>
      <div class="filing-serial">
        <p>
          互联网宗教信息服务许可证：{{
            contactUs.licence
          }}
          备案序号：{{ contactUs.filingNo }} 版权声明：
          {{ contactUs.copyright }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import common from '@/api/module/common'
export default {
  name: 'FooterView', //页脚
  data() {
    return {
      navData: [
        //底部导航数据
        {
          title: '南普陀寺',
          list: []
        },
        {
          title: '闽南佛学院',
          list: []
        },
        {
          title: '慈善会',
          list: []
        },
        {
          title: '政策法规',
          list: []
        }
      ],
      friendUrlList: [],
      contactUs: {}
    }
  },
  methods: {
    openNewPage(address) {
      this.$routerLink({ path: address })
    },
    //友情链接
    async getFriendPage() {
      try {
        const { data } = await common.commonFriendPage()
        this.friendUrlList = data
      } catch (error) {
        console.error(error || '请求失败')
      }
    },
    //底部链接
    async getBottomPage() {
      try {
        const { data } = await common.commonBottomPage()
        this.navData.forEach((item, index) => {
          for (const key in data) {
            if (Number(key) === index + 1)
              item.list = data[key]
          }
        })
      } catch (error) {
        console.error(error || '请求失败')
      }
    },
    // 联系我们
    async getCommonConfig() {
      try {
        // 联系我们 id2
        const { data } = await common.commonConfig(2)
        if (data)
          this.contactUs = this.$JSONtoObject(data.value)
      } catch (error) {
        console.error(error || '请求失败')
      }
    },
    init() {
      this.getFriendPage()
      this.getBottomPage()
      this.getCommonConfig()
    }
  },
  mounted() {
    this.init()
  }
}
</script>
<style lang="less" scoped>
.footer_view {
  .nav-box {
    padding: 0 160px;
    height: 513px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://f.nanputuo.com/nanputuo/img/home/pc_bg_01.png');
    background-size: 200% 200%;
    nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      ul {
        li {
          text-align: center;
          color: @font-color-695557;
          line-height: 30px;
          cursor: pointer;
        }
        .title {
          font-size: 28px;
          color: @main-color-A45E44;
          margin-bottom: 22px;
          line-height: unset;
        }
      }
    }
  }
  footer {
    position: relative;
    height: 401px;
    padding: 0 160px;
    background-color: @main-color-BFB2AA;
    .temple-info {
      display: flex;
      justify-content: space-between;
      .left {
        padding-top: 48px;
        ul {
          li {
            margin-bottom: 31px;
            display: flex;
            align-items: center;
            p {
              font-size: 16px;
              color: @font-color-695557;
              span {
                margin-right: 10px;
                a {
                  color: @font-color-695557;
                  text-decoration: none;
                }
                &:last-of-type {
                  margin-right: 0;
                }
              }
            }
            img {
              width: 18px;
              height: 16px;
              margin-right: 11px;
            }
          }
        }
      }
      .right {
        margin-top: 164px;
        ul {
          display: flex;
          justify-content: space-between;
          li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 28px;
            p {
              font-size: 16px;
              color: @font-color-695557;
            }
            .icon-box {
              width: 40px;
              height: 40px;
              background-color: @font-color-695557;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .filing-serial {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      p {
        font-size: 12px;
        color: @font-color-695557;
      }
    }
  }
}
.qr-code {
  width: 99px;
  height: 99px;
  margin: 0 auto;
  display: block;
}
</style>

import EmptyView from '@/components/EmptyView'
export default [
  {
    path: '/hospital',
    redirect: '/',
    component: EmptyView,
    meta: {
      name: '南普陀中医院',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    },
    children: [
      {
        path: '',
        name: 'hospital',
        component: () => import('@/views/hospital'),
        meta: {
          name: '南普陀中医院',
          noAddInRouteList: true,
          headerVisible: true,
          footerVisible: true,
          breadVisible: false,
          keepAlive: false
        }
      },
      {
        path: 'service',
        name: 'medicalService',
        component: () =>
          import('@/views/hospital/detail/service'),
        meta: {
          name: '医疗服务',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '医疗服务',
          keepAlive: false
        }
      },
      {
        path: 'experts',
        name: 'experts',
        component: () =>
          import('@/views/hospital/detail/experts'),
        meta: {
          name: '专家荟萃',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '专家荟萃',
          keepAlive: false
        }
      },
      {
        path: 'department',
        name: 'department',
        component: () =>
          import('@/views/hospital/detail/department'),
        meta: {
          name: '科室介绍',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '科室介绍',
          keepAlive: false
        }
      },
      {
        path: 'dynamic',
        name: 'hospitalDynamic',
        component: () =>
          import('@/views/hospital/detail/dynamic'),
        meta: {
          name: '医院动态',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '医院动态',
          keepAlive: false
        }
      },
      {
        path: 'education',
        name: 'hospitalEducation',
        component: () =>
          import('@/views/hospital/detail/education'),
        meta: {
          name: '医学人文',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '医学人文',
          keepAlive: false
        }
      },
      {
        path: 'garden',
        name: 'hospitalGarden',
        component: () =>
          import('@/views/hospital/detail/garden'),
        meta: {
          name: '护理园地',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '护理园地',
          keepAlive: false
        }
      },
      {
        path: 'recruit',
        name: 'recruit',
        component: () =>
          import('@/views/hospital/detail/recruit'),
        meta: {
          name: '人才招聘',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '人才招聘',
          keepAlive: false
        }
      },
      {
        path: 'intro',
        name: 'intro',
        component: () =>
          import('@/views/hospital/detail/intro'),
        meta: {
          name: '中医院简介',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '中医院简介',
          keepAlive: false
        }
      }
    ]
  }
]

import service from '../http'
import { verifyCodeBaseURL } from '@/api/config'

export default {
  // 首页配置
  commonConfig: params =>
    service({
      url: `/common/config/${params}`,
      method: 'get'
    }),
  //首页浮窗
  commonSide: params =>
    service({
      url: `/common/side/${params}`,
      method: 'get'
    }),
  //友情链接
  commonFriendPage: _ =>
    service({
      url: '/common/friend/page',
      method: 'get'
    }),
  //底部链接
  commonBottomPage: _ =>
    service({
      url: '/common/bottom/page',
      method: 'get'
    }),
  //头部导航
  commonHeaderNav: _ =>
    service({
      url: '/common/category/selectPage',
      method: 'get'
    }),
  //二级页面内容
  commonCategoryPage: params =>
    service({
      url: `/common/categoryPage/${params}`,
      method: 'get'
    }),
  //二级页面内容
  commonContentPage: params =>
    service({
      url: `/common/contentPage/page`,
      method: 'get',
      params
    }),
  //二级页面内容详情
  commonContentDetail: params =>
    service({
      url: `/common/contentDetail/${params}`,
      method: 'get'
    }),
  //全局搜索
  commonSearchContent: data =>
    service({
      url: `/common/searchContent`,
      method: 'post',
      data
    }),
  // Minio
  commonFileGetMinioToken: data =>
    service({
      baseURL: 'https://api.nanputuo.com/npt',
      url: `/common/file/getMinioToken`,
      method: 'post',
      data
    }),
  // 首页通用列表内容
  commonContentPageId: ({ id, page = 1 }) =>
    service({
      url: `/common/contentPage/${id}/${page}`,
      method: 'get'
    }),
  commonVisitor: (title) =>
    service({
      url: `/common/visitor?title=${title}`,
      method: 'get'
    }),
  commonIsAble: params =>
    service({
      url: `/common/isAble/${params}`,
      method: 'get'
    }),
  commonOauth: params =>
    service({
      url: `/oauth`,
      method: 'post',
      params
    }),
  commonDict: params =>
    service({
      url: `/common/dict/${params}`,
      method: 'get'
    })
}

export default [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login'),
  //   meta: {
  //     name: '登录',
  //     headerVisible: false,
  //     footerVisible: false,
  //     breadVisible: false,
  //     keepAlive: false,
  //     noStorage: true
  //   }
  // },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/login/search'),
    meta: {
      name: '搜索',
      headerVisible: true,
      footerVisible: true,
      breadVisible: true,
      keepAlive: false
    }
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import('@/views/login/userCenter'),
    meta: {
      name: '个人中心',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    }
  },
  // {
  //     path: '/bindPhone',
  //     name: 'bindPhone',
  //     component: () => import('@/views/login/bindPhone'),
  //     meta: {
  //         name: '绑定手机号',
  //         headerVisible: false,
  //         footerVisible: false,
  //         breadVisible: false,
  //         keepAlive: false,
  //         noStorage: true
  //     }
  // },
  {
    path: '/survey',
    name: 'survey',
    component: () => import('@/views/login/survey'),
    meta: {
      name: '问卷调查',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/login/terms'),
    meta: {
      name: '服务条款',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false,
      noStorage: true
    }
  },
  {
    path: '/leaveMessage',
    name: 'leaveMessage',
    component: () => import('@/views/login/leaveMessage'),
    meta: {
      name: '留言列表',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/login/Error403'),
    meta: {
      name: '403',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false,
      noStorage: true
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/login/Error404'),
    meta: {
      name: '404',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false,
      noStorage: true
    }
  }
]

import service from '../http'

export default {
  //密码登录接口
  passwordLogin: data =>
    service({
      url: '/member/login',
      method: 'post',
      data
    }),
  //短信登录接口
  messageLogin: data =>
    service({
      url: '/member/msgLogin',
      method: 'post',
      data
    }),
  //退出登录接口
  loginOut: data =>
    service({
      url: '/member/loginOut',
      method: 'post',
      data
    }),
  //密保问题
  quesPage: params =>
    service({
      url: `/member/quesPage/${params}`,
      method: 'get'
    }),
  // 找回密码密保问题验证
  verify: data =>
    service({
      url: `/member/verify`,
      method: 'post',
      data
    }),
  // 密保问题更新密码
  quesPwd: data =>
    service({
      url: `/member/quesPwd`,
      method: 'post',
      data
    }),
  // 找回密码手机号验证
  verifyPhone: data =>
    service({
      url: `/member/verifyPhone`,
      method: 'post',
      data
    }),
  // 手机号更新密码
  phonePwd: data =>
    service({
      url: `/member/phonePwd`,
      method: 'post',
      data
    }),
  // 二维码登录
  wxLogin: data =>
    service({
      url: '/member/wxLogin',
      method: 'post',
      data
    })
}

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
    invalidNumber: 0
  },
  actions: {},
  mutations: {
    // 设置token
    SET_USER_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    // 记录登录失效次数
    SET_INVALID_NUMBER(state, reset) {
      if (reset) state.invalidNumber = 0
      else state.invalidNumber++
    }
  },
  getters: {}
}

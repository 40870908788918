var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_view"},[_c('section',{staticClass:"first-level-nav"},[_c('nav',[_c('ul',{staticClass:"left HBKX"},_vm._l((_vm.headerNavData.left),function({ label, path, children },index){return _c('li',{key:index,class:_vm.$route.path === path ||
              _vm.$route.path.includes(path)
                ? 'is-active'
                : '',on:{"click":function($event){_vm.$router.push(path)
              _vm.showSecondLevelNav = false},"mouseenter":function($event){return _vm.showSecondLevelNavBox(label, children, $event)}}},[_c('p',[_vm._v(" "+_vm._s(label)+" ")]),(
                _vm.$route.path === path ||
                _vm.$route.path.includes(path)
              )?_c('img',{staticClass:"flower",attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/莲花.png","alt":"flower"}}):_vm._e()])}),0),_vm._m(0),_c('ul',{staticClass:"right HBKX"},_vm._l((_vm.headerNavData.right),function({ label, path, children },index){return _c('li',{key:index,class:_vm.$route.path === path ||
              _vm.$route.path.includes(path)
                ? 'is-active'
                : '',on:{"click":function($event){_vm.$router.push(path)
              _vm.showSecondLevelNav = false},"mouseenter":function($event){return _vm.showSecondLevelNavBox(label, children, $event)}}},[_c('p',[_vm._v(_vm._s(label))]),(
                _vm.$route.path === path ||
                _vm.$route.path.includes(path)
              )?_c('img',{staticClass:"flower",attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/莲花.png","alt":"flower"}}):_vm._e()])}),0)]),_c('div',{staticClass:"user-setting SourceHanSerifCN-Regular"},[_c('aside',[(!_vm.token)?_c('button',{staticClass:"SourceHanSerifCN-Regular",on:{"click":_vm.userLogin}},[_vm._v(" 用户登录 ")]):_c('el-dropdown',{ref:"myDropdown",attrs:{"placement":"bottom"},on:{"command":_vm.userCenterCommand}},[_c('el-button',[_vm._v(" 个人中心"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown","append-to-body":false},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"userCenter"}},[_vm._v("个人中心")]),_c('el-dropdown-item',{attrs:{"command":"logOut"}},[_vm._v("退出登录")])],1)],1)],1),_c('section',[_c('div',{staticClass:"icon",on:{"click":_vm.loginOrUserCenter,"mouseenter":function($event){_vm.token && _vm.$refs.myDropdown.show()},"mouseleave":function($event){_vm.token && _vm.$refs.myDropdown.hide()}}},[_c('img',{attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/登录或个人中心.png","alt":"登录或个人中心"}})]),_c('div',{staticClass:"icon",on:{"click":function($event){return _vm.$router.push('/leaveMessage')}}},[_c('img',{attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/留言.png","alt":"留言"}})]),_vm._m(1),_c('div',{staticClass:"icon",on:{"click":function($event){return _vm.$router.push('/search')}}},[_c('img',{attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/搜索.png","alt":"搜索"}})])])])]),_c('transition',{attrs:{"name":"second-level-nav"}},[(_vm.showSecondLevelNav)?_c('nav',{staticClass:"second-level-nav",style:({
          paddingLeft: `${_vm.secondLevelNavPaddingLeft}px`
        }),on:{"mouseleave":function($event){_vm.showSecondLevelNav = false}}},[_c('transition',{attrs:{"name":"second-level-nav-ul"}},[(_vm.showSecondLevelNavUl)?_c('ul',{ref:"secondLevelNav",staticClass:"HBKX"},_vm._l((_vm.secondLevelNavList),function({ label, path, id },index){return _c('li',{key:index,class:_vm.$route.path.includes(path)
                  ? 'is-active'
                  : '',on:{"click":function($event){return _vm.goSecondPage(id, path)}}},[_c('p',_vm._l((label),function(n,nIndex){return _c('span',{key:nIndex},[_vm._v(" "+_vm._s(n)+" ")])}),0)])}),0):_vm._e()])],1):_vm._e()]),_c('MyCarousel',{attrs:{"height":`${_vm.$route.path === '/home' ? 600 : 460}px`,"diyArrow":false,"autoplay":"","carouselImgList":_vm.carouselImgList,"showName":_vm.$route.path === '/home' ? true : false}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{staticClass:"brown-bg",attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/logobg.png","alt":"logobg"}}),_c('img',{staticClass:"white-logo",attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/logo.png","alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('a',{attrs:{"href":"https://en.nanputuo.com/","target":"_blank"}},[_c('img',{attrs:{"src":"https://f.nanputuo.com/nanputuo/img/home/语言切换.png","alt":"语言切换"}})])])
}]

export { render, staticRenderFns }
import EmptyView from '@/components/EmptyView'
export default [
  {
    path: '/charity',
    redirect: '/',
    component: EmptyView,
    meta: {
      name: '慈善会',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    },
    children: [
      {
        path: '',
        name: 'charity',
        component: () => import('@/views/charity'),
        meta: {
          name: '慈善会',
          noAddInRouteList: true,
          headerVisible: true,
          footerVisible: true,
          breadVisible: false,
          keepAlive: false
        }
      },
      {
        path: 'support',
        name: 'support',
        component: () =>
          import('@/views/charity/detail/support'),
        meta: {
          name: '支持慈善',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '支持慈善',
          keepAlive: false
        }
      },
      {
        path: 'message',
        name: 'message',
        component: () =>
          import('@/views/charity/detail/message'),
        meta: {
          name: '信息公开',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '信息公开',
          keepAlive: false
        }
      },
      {
        path: 'organ',
        name: 'organ',
        component: () =>
          import('@/views/charity/detail/organ'),
        meta: {
          name: '机构简介',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '机构简介',
          keepAlive: false
        }
      },
      {
        path: 'dynamic',
        name: 'dynamic',
        component: () =>
          import('@/views/charity/detail/dynamic'),
        meta: {
          name: '慈善动态',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '慈善动态',
          keepAlive: false
        }
      },
      {
        path: 'project',
        name: 'project',
        component: () =>
          import('@/views/charity/detail/project'),
        meta: {
          name: '项目概览',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '项目概览',
          keepAlive: false
        }
      },
      {
        path: 'heart',
        name: 'heart',
        component: () =>
          import('@/views/charity/detail/heart'),
        meta: {
          name: '心灵慈善',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '心灵慈善',
          keepAlive: false
        }
      }
    ]
  }
]

import common from '@/api/module/common'
const dict = {}
;(async () => {
  const { data } = await common.commonDict('article_d_img')
  dict.articleImgUrl = data.dictValue
})()
export const globalMixin = {
  computed: {
    // 是否移动设备
    isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    },
    articleDefaultImg() {
      return url => url || dict.articleImgUrl
    }
  }
}

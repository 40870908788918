import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { baseURL, $url } from '@/api/config'
import { Message } from 'element-ui'

// 创建 axios 实例
const service = axios.create({
  baseURL,
  headers: {
    get: {
      'Content-Type':
        'application/x-www-form-urlencoded;charset=utf-8'
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  },

  // 是否跨站点访问控制请求
  withCredentials: false,
  timeout: 30000,
  //请求数据转换
  transformRequest: [data => JSON.stringify(data)],
  //相应数据转换
  transformResponse: [
    data => {
      if (typeof data === 'string' && data.startsWith('{'))
        data = JSON.parse(data)
      return data
    }
  ]
})

service.interceptors.request.use(
  config => {
    config.headers['auth-token'] =
      localStorage.getItem('token')
    return config
  },
  error => {
    return Promise.resolve(error)
  }
)

service.interceptors.response.use(
  res => {
    if (res.headers.token) {
      localStorage.setItem('token', res.headers.token)
      store.state.authAbout.token = res.headers.token
    }
    const code = res.data.code || 0
    const msg = res.data.msg || 'Exception'
    if (
      res.request.responseType === 'blob' ||
      res.request.responseType === 'arraybuffer'
    )
      return res
    if (code === 0) return res.data
    else {
      switch (code) {
        case 1:
          if (msg === '存在敏感词')
            Message.error(
              res.data.data?.reduce(
                (pre, current) => `${pre},${current}`
              )
            )
          break
        case 401:
          localStorage.removeItem('token')
          store.commit('authAbout/SET_USER_TOKEN', '')
          if (store.state.authAbout.invalidNumber < 1) {
            Message.error('登录已过期， 请重新登录')
            setTimeout(() => {
              // router.push('/login')
              window.location.href = $url
            }, 700)
          }
          store.commit('authAbout/SET_INVALID_NUMBER')
          break
        case 403:
          Message.error(msg)
          setTimeout(() => {
            router.push('/403')
          }, 500)
          break
        case 404:
          Message.error(msg)
          setTimeout(() => {
            router.push('/404')
          }, 500)
          break
        default:
          Message.error(msg)
          break
      }
      return Promise.reject(msg)
    }
  },
  ({ message }) => {
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (
      message.includes('Request failed with status code')
    ) {
      message =
        '系统接口' +
        message.substr(message.length - 3) +
        '异常'
    }
    Message({
      message,
      type: 'error',
      duration: 5000
    })
    return Promise.reject(message)
  }
)

export default service

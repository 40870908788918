import dayjs from 'dayjs'
import ElementUI from 'element-ui'
import MetaInfo from 'vue-meta-info'
import BaiduMap from 'vue-baidu-map'
import '@/assets/style/css/reset.css'
import '@/assets/style/css/normalize.css'
import '@/assets/style/css/index.css'
import '@/assets/style/css/common.css'
import '@/assets/style/less/common.less'
import 'element-ui/lib/theme-chalk/index.css'
import { globalMixin } from '@/utils/mixin'
import EmptyData from '@/components/EmptyData'
import PublicNotice from '@/components/PublicNotice'
import {
  JSONtoObject,
  JSONtoArray,
  detailGoBack,
  routerLink
} from '@/utils/common'

export default {
  install(Vue) {
    Vue.use(ElementUI)
    Vue.use(MetaInfo)
    Vue.use(BaiduMap, {
      ak: 'LBeD8ArUXikpl2vrdmkSQbMLpT5Gr4p3'
    })
    Vue.mixin(globalMixin)
    Vue.component('EmptyData', EmptyData)
    Vue.component('PublicNotice', PublicNotice)
    Vue.filter('formatTimeYYYY', value => {
      if (value) {
        if (typeof value === 'string')
          return `${dayjs(Number(value)).format('YYYY')}年`
        else if (typeof value === 'number')
          return `${dayjs(value).format('YYYY')}年`
      } else return ''
    })
    Vue.filter('formatTimeYYMMDD', value => {
      if (value) {
        // 检查value是否是符合YYYY-MM-DD格式的日期字符串
        if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
          return dayjs(value).format('YYYY-MM-DD');
        } 
        // 如果value是数字，则假定它是一个时间戳
        else if (typeof value === 'number') {
          return dayjs(value).format('YYYY-MM-DD');
        }
      } 
      return '';
    })
    Vue.prototype.$JSONtoObject = JSONtoObject
    Vue.prototype.$JSONtoArray = JSONtoArray
    Vue.prototype.$routerLink = routerLink
    Vue.prototype.$detailGoBack = detailGoBack
    Vue.config.productionTip = false
    // if(window.devicePixelRatio !== 1) {
    //     // 设备像素比率不为1时 调整body元素的缩放比例
    //     const body = document.body
    //     body.style.zoom = 1 / window.devicePixelRatio
    // }
  }
}

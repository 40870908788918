import service from '../http'

export default {
  // 师资队伍分类
  collegeTeamCategory: _ =>
    service({
      url: '/college/team/selectCategory',
      method: 'get'
    }),
  // 师资队伍列表
  collegeContentPage: data =>
    service({
      url: `/college/team/contentPage`,
      method: 'post',
      data
    }),
  // 师资队伍列表 => 全部
  collegeContentAllPage: data =>
    service({
      url: `/college/team/contentAllPage`,
      method: 'post',
      data
    }),
  // 师资队伍详情
  collegeContentDetail: params =>
    service({
      url: `/college/team/contentDetail/${params}`,
      method: 'get'
    }),
  // 课程设置分类
  collegeCourseCategory: _ =>
    service({
      url: `/college/course/selectCategory`,
      method: 'get'
    }),
  // 课程设置内容
  collegeCourseContentPage: params =>
    service({
      url: `/college/course/contentPage/${params}`,
      method: 'get'
    }),
  // 课程设置列表详情
  collegeCourseContentDetail: params =>
    service({
      url: `/college/course/contentDetail/${params}`,
      method: 'get'
    }),
  // 课程设置最新的一条内容
  collegeCourseLastContent: _ =>
    service({
      url: `/college/course/lastContent`,
      method: 'get'
    }),
  // 经典馆藏分类
  collegeLibraryCategory: _ =>
    service({
      url: `/college/library/selectCategory`,
      method: 'get'
    }),
  // 经典馆藏内容
  collegeLibraryContentPage: data =>
    service({
      url: `/college/library/contentPage`,
      method: 'post',
      data
    }),
  // 经典馆藏内容详情
  collegeLibraryContentDetail: params =>
    service({
      url: `/college/library/contentDetail/${params}`,
      method: 'get'
    }),
  // 阅读
  contentPdfClickDetail: params =>
    service({
      url: `/common/contentPdfClick/${params}`,
      method: 'get'
    })
}

<template>
  <div class="empty-data">
    <img
      src="https://f.nanputuo.com/nanputuo/img/public/qxy_zwnr.png"
      alt="暂无内容"
    />
    <p>暂无内容</p>
  </div>
</template>

<script>
export default {
  name: 'EmptyData' //空数据组件
}
</script>
<style lang="less" scoped>
.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  padding: 240px 0;
  img {
    width: 418px;
    height: 265px;
  }
  p {
    width: 418px;
    padding-left: 151px;
    color: #999999;
    font-size: 18px;
  }
}
</style>

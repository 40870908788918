import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import login from './module/login'
import home from './module/home'
import temple from './module/temple'
import news from './module/news'
import promote from './module/promote'
import college from './module/college'
import charity from './module/charity'
import society from './module/society'
import hospital from './module/hospital'
import regulations from './module/regulations'

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    ...login,
    ...home,
    ...temple,
    ...news,
    ...promote,
    ...college,
    ...charity,
    ...society,
    ...hospital,
    ...regulations
  ]
})

//需要鉴权的路由
const authRoutes = ['/bindPhone', '/userCenter']
router.beforeEach((to, _, next) => {
  if (
    authRoutes.includes(to.path) &&
    (!store.state.authAbout.token ||
      !localStorage.getItem('token'))
  )
    return router.back()
  next()
})

router.afterEach(to => {
  const [parent = {}, children = {}] = [
    ...to.matched
  ].filter(
    ({ meta: { noAddInRouteList } }) => !noAddInRouteList
  )
  const parentName = parent?.meta?.name || ''
  const childrenName = children?.meta?.name || ''
  const title = `${childrenName}${
    childrenName ? '-' : ''
  }${parentName}-厦门南普陀寺`
  document.title = title
})

// 解决路由重复点击报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

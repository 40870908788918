<template>
  <el-carousel
    ref="myCarousel"
    class="my-carousel"
    trigger="click"
    arrow="never"
    :autoplay="autoplay"
    :indicator-position="indicatorPosition"
    :height="height"
  >
    <el-carousel-item
      v-for="(item, index) in carouselImgList"
      :key="index"
      @click.native="
        $route.path !== '/search' &&
          $routerLink({ path: item.uri })
      "
    >
      <MyShadow :opacity="0.1" />
      <img
        class="el-carousel-item-img"
        :src="item.img"
        alt="banner"
      />
      <!-- 广告名 -->
      <div
        class="listName SourceHanSerifCN-Regular"
        v-if="
          showName && item.name && $route.path !== '/search'
        "
      >
        <p class="toe">{{ item.name }}</p>
      </div>
    </el-carousel-item>
    <!-- 自定义左右箭头 -->
    <template v-if="diyArrow">
      <div
        class="diy-arrow-left"
        @click.stop="$refs.myCarousel.prev()"
      >
        <img
          :style="diyArrowSize"
          src="https://f.nanputuo.com/nanputuo/img/public/left-arrow.png"
          alt="arrow"
        />
      </div>
      <div
        class="diy-arrow-right"
        @click.stop="$refs.myCarousel.next()"
      >
        <img
          :style="diyArrowSize"
          src="https://f.nanputuo.com/nanputuo/img/public/right-arrow.png"
          alt="arrow"
        />
      </div>
    </template>
    <!-- 轮播图上的标题 -->
    <div
      v-if="$route.meta.textOnCarousel"
      class="text HBKX"
    >
      <p>{{ $route.meta.textOnCarousel }}</p>
      <img
        src="https://f.nanputuo.com/nanputuo/img/home/弘法利生小图标.png"
      />
    </div>
    <!-- 搜索框 -->
    <div
      v-if="$route.path === '/search'"
      class="search-input-box SourceHanSerifCN-Regular"
    >
      <div class="container">
        <div class="top">
          <div class="icon">
            <i class="el-icon-search"></i>
          </div>
          <input
            type="text"
            v-model="content"
            placeholder="请输入搜索内容"
            @keyup.enter="getSearchKey"
          />
          <p @click="getSearchKey">搜索</p>
        </div>
      </div>
    </div>
  </el-carousel>
</template>

<script>
import MyShadow from './MyShadow'
export default {
  name: 'MyCarousel', //轮播图组件
  props: {
    carouselImgList: {
      //图片数组
      required: true,
      type: Array
    },
    height: {
      // 轮播图高度
      required: true,
      type: String
    },
    autoplay: {
      // 是否自动播放
      type: Boolean,
      default: false
    },
    indicatorPosition: {
      //指示器（白色圆点）
      type: String,
      default() {
        // 如果传进来的图片数组长度小于或等于1， 不显示轮播图上面的指示器（白色圆点）
        if (this.carouselImgList.length <= 1) return 'none'
        else return ''
      }
    },
    diyArrow: {
      // 自定义箭头
      type: Boolean,
      default: true
    },
    diyArrowSize: {
      // 自定义箭头大小
      type: Object,
      default() {
        return {
          width: '29px',
          height: '51px'
        }
      }
    },
    showName: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MyShadow
  },
  data() {
    return {
      content: '' // 搜索框值
    }
  },
  methods: {
    // 传递搜索框的值
    getSearchKey() {
      this.$bus.$emit('searchKey', this.content)
    }
  },
  watch: {
    $route(newVal) {
      if (newVal.path !== '/search') this.content = ''
    }
  }
}
</script>

<style lang="less" scoped>
.my-carousel {
  :deep(.el-carousel__container) {
    .el-carousel__item {
      cursor: pointer;
      .el-carousel-item-img {
        
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      overflow: hidden;
      .listName {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: 30px;
        padding-right: 30px;
        width: 100%;
        height: 15%;
        background: rgba(253, 253, 253, 0.6);
        z-index: 999;
        display: flex;
        align-items: center;
        p {
          font-size: 28px;
          color: @font-color-695557;
        }
      }
    }
    .text {
      position: absolute;
      left: 539px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 56px;
        color: @main-color-F9F9F5;
      }
      img {
        width: 17px;
        height: 16px;
        position: relative;
        bottom: -20px;
        left: -10px;
      }
    }
    .search-input-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      .container {
        .top {
          width: 879px;
          height: 61px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          color: @main-color-BFB2AA;
          border-radius: 8px;
          border: 1px solid @main-color-BFB2AA;
          .icon {
            margin: 0 15px 0 21px;
            i {
              font-size: 32px;
            }
          }
          input {
            flex: 1;
            border: unset;
            font-size: 18px;
            margin-right: 21px;
            &::placeholder {
              font-size: 18px;
              color: @main-color-BFB2AA;
            }
          }
          p {
            cursor: pointer;
            margin: 0 21px;
          }
        }
      }
    }
    .diy-arrow-left,
    .diy-arrow-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      cursor: pointer;
      color: #fff;
    }
    .diy-arrow-left {
      left: 16px;
    }
    .diy-arrow-right {
      right: 16px;
    }
  }
  :deep(.el-carousel__indicators) {
    .el-carousel__indicator {
      padding: 14px 7px;
      &.is-active {
        .el-carousel__button {
          background-color: #ffffff;
        }
      }
      .el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: unset;
        background-color: unset;
        border: 1px solid #ffffff;
      }
    }
  }
}
</style>

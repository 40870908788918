<template>
  <div class="public-notice">
    <div class="go-back" @click="back">
      <div class="arrow-left">
        <i class="el-icon-arrow-left"></i>
      </div>
      <p class="SourceHanSerifCN-Regular">返回</p>
    </div>
    <div class="notice">
      <div class="title-box">
        <div class="title SourceHanSerifCN-Regular" v-html="noticeInfo.title"></div>
        <div class="pdf-boxs" v-if="noticeInfo.pdf" @click="lookPdf(noticeInfo.pdf)">
          <div class="pdf-box-top">
            <img src="@/assets/img/college/pdf.png" class="pdf-img" alt="" srcset="">
            <span>查看</span>
          </div>
          <div class="pdf-box">查看次数：{{noticeInfo.pdfClicks}}</div>
        </div>
      </div>
      <div class="line-box SourceHanSerifCN-Regular" v-html="noticeInfo.content"></div>
      <div class="form SourceHanSerifCN-Regular">
        <div v-if="noticeInfo.author" class="form-item">作者：{{noticeInfo.author}}</div>
        <div v-if="noticeInfo.souce" class="form-item">来源：{{noticeInfo.souce}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import college from '@/api/module/college'
export default {
  name: 'PublicNotice', // 文章详情组件
  props: {
    noticeInfo: {
      type: Object
    },
    value: {
      type: Boolean
    }
  },
  model: {
    prop: 'value',
    event: 'back'
  },
  methods: {
    back() {
      this.$detailGoBack()
      this.$emit('back', false)
    },
    async lookPdf(url) {
      await college.contentPdfClickDetail(this.noticeInfo.contentId)
      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.public-notice {
  .go-back {
    position: relative;
    top: -38px;
    cursor: pointer;
    display: flex;
    align-items: center;
    .arrow-left {
      margin-right: 6px;
      i {
        font-size: 14px;
        color: @main-color-A45E44;
        font-weight: bold;
      }
    }
    p {
      font-size: 16px;
      color: @main-color-A45E44;
    }
  }
  .notice {
    padding-bottom: 62px;
    background-color: @main-color-F9F9F5;
    .title {
      margin-bottom: 46px;
      text-align: center;
      padding-top: 38px;
      padding-bottom: 30px;
      font-size: 34px;
      color: @font-color-695557;
      border-bottom: 1px solid @main-color-BFB2AA;
    }
    video,
    audio {
      display: block;
      margin: 0 auto;
      width: 900px;
      margin-bottom: 10px;
    }
    video {
      height: 500px;
    }
    :deep(.line-box) {
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      line-height: 30px;
      p {
        font-size: 18px;
        padding: 0 65px;
        color: @font-color-695557;
        line-height: 36px;
        margin: 15px 0;
      }
    }
    .form {
      padding: 30px 85px 0;
      .form-item {
        margin-bottom: 16px;
        color: #695557;
        font-size: 18px;
      }
    }
  }
}
.title-box {
  position: relative;
  .pdf-boxs {
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    color: #333;
    position: absolute;
    right: 10px;
    top: 22px;
    cursor: pointer;
    .pdf-box-top {
      background-color: rgb(164, 94, 68);
      color: #ffffff;
      display: inline-block;
      margin: 0 auto;
      padding: 4px 10px;
      margin-bottom: 4px;
    }
    img {
      width: 20px;
      height: auto;
      vertical-align: sub;
      // vertical-align: inherit;
      margin-right: 4px;
    }
    // rgb(164, 94, 68);
    background-color: #f9f9f5;
  }
}
</style>

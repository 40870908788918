import EmptyView from '@/components/EmptyView'
export default [
  {
    path: '/promote',
    redirect: '/',
    component: EmptyView,
    meta: {
      name: '弘法利生',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    },
    children: [
      {
        path: '',
        name: 'promote',
        component: () => import('@/views/promote'),
        meta: {
          name: '弘法利生',
          noAddInRouteList: true,
          headerVisible: true,
          footerVisible: true,
          breadVisible: false,
          keepAlive: false
        }
      },
      {
        path: 'cloud',
        name: 'cloud',
        component: () =>
          import('@/views/promote/detail/cloud/index.vue'),
        meta: {
          name: '巡　　礼',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '巡　　礼',
          keepAlive: false
        }
      },
      {
        path: 'questions',
        name: 'questions',
        component: () =>
          import('@/views/promote/detail/questions'),
        meta: {
          name: '学佛答疑',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '学佛答疑',
          keepAlive: false
        }
      },
      {
        path: 'sacrifice',
        name: 'sacrifice',
        component: () =>
          import(
            '@/views/promote/detail/sacrifice/index.vue'
          ),
        meta: {
          name: '在线祭祀',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '在线祭祀',
          keepAlive: false
        }
      },
      {
        path: 'study',
        name: 'study',
        component: () =>
          import('@/views/promote/detail/study'),
        meta: {
          name: '学修',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '学修',
          keepAlive: false
        }
      },
      {
        path: 'volunteer',
        name: 'volunteer',
        component: () =>
          import('@/views/promote/detail/volunteer'),
        meta: {
          name: '义工服务',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '义工服务',
          keepAlive: false
        }
      },
      {
        path: 'window',
        name: 'window',
        component: () =>
          import('@/views/promote/detail/window'),
        meta: {
          name: '心灵窗口',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '心灵窗口',
          keepAlive: false
        }
      }
    ]
  }
]

<template>
  <div id="app">
    <LayoutsView />
  </div>
</template>
<script>
import LayoutsView from './layouts'
export default {
  name: 'App',
  components: {
    LayoutsView
  }
}
</script>

export default [
  {
    path: '/regulations',
    name: 'regulations',
    component: () => import('@/views/regulations'),
    meta: {
      name: '政策法规',
      headerVisible: true,
      footerVisible: true,
      breadVisible: false,
      keepAlive: false
    }
  }
]

import EmptyView from '@/components/EmptyView'
export default [
  {
    path: '/news',
    redirect: '/',
    component: EmptyView,
    meta: {
      name: '寺院资讯',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    },
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/views/news'),
        meta: {
          name: '寺院资讯',
          noAddInRouteList: true,
          headerVisible: true,
          footerVisible: true,
          breadVisible: false,
          keepAlive: false
        }
      },
      {
        path: 'latest',
        name: 'latest',
        component: () =>
          import('@/views/news/detail/latest'),
        meta: {
          name: '最新动态',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '最新动态',
          keepAlive: false
        }
      },
      {
        path: 'collection',
        name: 'collection',
        component: () =>
          import('@/views/news/detail/collection'),
        meta: {
          name: '专题集锦',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '专题集锦',
          keepAlive: false
        }
      },
      {
        path: 'year',
        name: 'year',
        component: () => import('@/views/news/detail/year'),
        meta: {
          name: '历年新闻汇总',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '历年新闻汇总',
          keepAlive: false
        }
      },
      {
        path: 'notice',
        name: 'notice',
        component: () =>
          import('@/views/news/detail/notice'),
        meta: {
          name: '寺院公告',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '寺院公告',
          keepAlive: false
        }
      },
      {
        path: 'activity',
        name: 'activity',
        component: () =>
          import('@/views/news/detail/activity'),
        meta: {
          name: '特色活动',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '特色活动',
          keepAlive: false
        }
      },
      {
        path: 'info',
        name: 'info',
        component: () => import('@/views/news/detail/info'),
        meta: {
          name: '佛教资讯',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '佛教资讯',
          keepAlive: false
        }
      }
    ]
  }
]

import EmptyView from '@/components/EmptyView'
export default [
  {
    path: '/college',
    redirect: '/',
    component: EmptyView,
    meta: {
      name: '闽南佛学院',
      headerVisible: false,
      footerVisible: false,
      breadVisible: false,
      keepAlive: false
    },
    children: [
      {
        path: '',
        name: 'college',
        component: () => import('@/views/college'),
        meta: {
          name: '闽南佛学院',
          noAddInRouteList: true,
          headerVisible: true,
          footerVisible: true,
          breadVisible: false,
          keepAlive: false
        }
      },
      {
        path: 'library',
        name: 'library',
        component: () =>
          import('@/views/college/detail/library'),
        meta: {
          name: '太虚图书馆',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '太虚图书馆',
          keepAlive: false
        }
      },
      {
        path: 'team',
        name: 'team',
        component: () =>
          import('@/views/college/detail/team'),
        meta: {
          name: '师资队伍',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '师资队伍',
          keepAlive: false
        }
      },
      {
        path: 'education',
        name: 'education',
        component: () =>
          import('@/views/college/detail/education'),
        meta: {
          name: '教育教学',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '教育教学',
          keepAlive: false
        }
      },
      {
        path: 'courtyard',
        name: 'courtyard',
        component: () =>
          import('@/views/college/detail/courtyard'),
        meta: {
          name: '闽院介绍',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '闽院介绍',
          keepAlive: false
        }
      },
      {
        path: 'fruit',
        name: 'fruit',
        component: () =>
          import('@/views/college/detail/fruit'),
        meta: {
          name: '学术成果',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '学术成果',
          keepAlive: false
        }
      },
      {
        path: 'garden',
        name: 'garden',
        component: () =>
          import('@/views/college/detail/garden'),
        meta: {
          name: '学生园地',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '学生园地',
          keepAlive: false
        }
      },
      {
        path: 'info',
        name: 'collegeInfo',
        component: () =>
          import('@/views/college/detail/info'),
        meta: {
          name: '学院资讯',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '学院资讯',
          keepAlive: false
        }
      },
      {
        path: 'build',
        name: 'build',
        component: () =>
          import('@/views/college/detail/build'),
        meta: {
          name: '下院建设',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '下院建设',
          keepAlive: false
        }
      },
      {
        path: 'kan',
        name: 'kan',
        component: () =>
          import('@/views/college/detail/kan'),
        meta: {
          name: '海潮音(中英文)',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '海潮音(中英文)',
          keepAlive: false
        }
      },
      {
        path: 'work',
        name: 'work',
        component: () =>
          import('@/views/college/detail/work'),
        meta: {
          name: '组织架构',
          headerVisible: true,
          footerVisible: true,
          breadVisible: true,
          textOnCarousel: '组织架构',
          keepAlive: false
        }
      }
    ]
  }
]

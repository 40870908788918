<template>
  <div class="content_view">
    <MyBread v-if="$route.meta.breadVisible" />
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
    ></router-view>
  </div>
</template>

<script>
import MyBread from '@/components/MyBread'
export default {
  name: 'ContentView', // 路由页面容器
  components: {
    MyBread
  }
}
</script>

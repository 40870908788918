import service from '../http'

export default {
  // 留言接口
  applyMessage: data =>
    service({
      url: '/apply/message',
      method: 'post',
      data
    }),
  // 二级功能背景
  secondFunctionBgImg: params =>
    service({
      url: `/apply/detail/${params}`,
      method: 'get'
    })
}

import dayjs from 'dayjs'
import router from '@/router'
import { JSEncrypt } from 'jsencrypt'

//密码加密
export const getRSApassword = (psw = '') => {
  const pubKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbZDzEtAYN5FbwPFi3t5UpueXNwHW7hYEtevfECFBfROhEv4jcdNA1g9T6XnUSLfM/9/DBNoP8MzTeLZ/4PteLf2KvnKWBvxudObuPkOQ3M/G/B+/OtuphA77gNx499FOB/KoeoFubMXrWKK9mFw7Vz70txEKuiTtLOAvj3HAwewIDAQAB'
  const encryptStr = new JSEncrypt()
  encryptStr.setPublicKey(pubKey)
  return encryptStr.encrypt(psw)
}

// 对象深拷贝
export const deepClone = (obj = {}) => {
  // 定义一个变量 并判断是数组还是对象
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object' && obj != null) {
    // 判断obj存在并且是对象类型的时候 因为null也是object类型，所以要单独做判断
    for (let key in obj) {
      // 循环对象类型的obj
      if (obj.hasOwnProperty(key)) {
        // 判断obj中是否存在key属性
        if (obj[key] && typeof obj[key] === 'object') {
          // 判断如果obj[key]存在并且obj[key]是对象类型的时候应该深拷贝，即在堆内存中开辟新的内存
          objClone[key] = deepClone(obj[key])
        } else {
          // 否则就是浅复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}

// 页面滚动到顶部
export const scrollPageTop = (top = 0, progress = 0.26) => {
  const st =
    document.documentElement.scrollTop ||
    document.body.scrollTop
  const sh =
    document.documentElement.scrollHeight ||
    document.body.scrollHeight
  if (st > 0 && st / sh >= progress) {
    window.scrollTo({ top, behavior: 'smooth' })
  }
}

// 公历转农历
export const sloarToLunar = (sy = '', sm = '', sd = '') => {
  let lunarYearArr = [
      0x0b557, //1949
      0x06ca0,
      0x0b550,
      0x15355,
      0x04da0,
      0x0a5b0,
      0x14573,
      0x052b0,
      0x0a9a8,
      0x0e950,
      0x06aa0, //1950-1959
      0x0aea6,
      0x0ab50,
      0x04b60,
      0x0aae4,
      0x0a570,
      0x05260,
      0x0f263,
      0x0d950,
      0x05b57,
      0x056a0, //1960-1969
      0x096d0,
      0x04dd5,
      0x04ad0,
      0x0a4d0,
      0x0d4d4,
      0x0d250,
      0x0d558,
      0x0b540,
      0x0b6a0,
      0x195a6, //1970-1979
      0x095b0,
      0x049b0,
      0x0a974,
      0x0a4b0,
      0x0b27a,
      0x06a50,
      0x06d40,
      0x0af46,
      0x0ab60,
      0x09570, //1980-1989
      0x04af5,
      0x04970,
      0x064b0,
      0x074a3,
      0x0ea50,
      0x06b58,
      0x055c0,
      0x0ab60,
      0x096d5,
      0x092e0, //1990-1999
      0x0c960,
      0x0d954,
      0x0d4a0,
      0x0da50,
      0x07552,
      0x056a0,
      0x0abb7,
      0x025d0,
      0x092d0,
      0x0cab5, //2000-2009
      0x0a950,
      0x0b4a0,
      0x0baa4,
      0x0ad50,
      0x055d9,
      0x04ba0,
      0x0a5b0,
      0x15176,
      0x052b0,
      0x0a930, //2010-2019
      0x07954,
      0x06aa0,
      0x0ad50,
      0x05b52,
      0x04b60,
      0x0a6e6,
      0x0a4e0,
      0x0d260,
      0x0ea65,
      0x0d530, //2020-2029
      0x05aa0,
      0x076a3,
      0x096d0,
      0x04afb,
      0x04ad0,
      0x0a4d0,
      0x1d0b6,
      0x0d250,
      0x0d520,
      0x0dd45, //2030-2039
      0x0b5a0,
      0x056d0,
      0x055b2,
      0x049b0,
      0x0a577,
      0x0a4b0,
      0x0aa50,
      0x1b255,
      0x06d20,
      0x0ada0, //2040-2049
      0x14b63,
      0x09370,
      0x049f8,
      0x04970,
      0x064b0,
      0x168a6,
      0x0ea50,
      0x06b20,
      0x1a6c4,
      0x0aae0, //2050-2059
      0x0a2e0,
      0x0d2e3,
      0x0c960,
      0x0d557,
      0x0d4a0,
      0x0da50,
      0x05d55,
      0x056a0,
      0x0a6d0,
      0x055d4, //2060-2069
      0x052d0,
      0x0a9b8,
      0x0a950,
      0x0b4a0,
      0x0b6a6,
      0x0ad50,
      0x055a0,
      0x0aba4,
      0x0a5b0,
      0x052b0, //2070-2079
      0x0b273,
      0x06930,
      0x07337,
      0x06aa0,
      0x0ad50,
      0x14b55,
      0x04b60,
      0x0a570,
      0x054e4,
      0x0d160, //2080-2089
      0x0e968,
      0x0d520,
      0x0daa0,
      0x16aa6,
      0x056d0,
      0x04ae0,
      0x0a9d4,
      0x0a2d0,
      0x0d150,
      0x0f252, //2090-2099
      0x0d520 //2100
    ],
    lunarMonth = [
      '正',
      '二',
      '三',
      '四',
      '五',
      '六',
      '七',
      '八',
      '九',
      '十',
      '冬',
      '腊'
    ],
    lunarDay = [
      '一',
      '二',
      '三',
      '四',
      '五',
      '六',
      '七',
      '八',
      '九',
      '十',
      '初',
      '廿'
    ],
    tianGan = [
      '甲',
      '乙',
      '丙',
      '丁',
      '戊',
      '己',
      '庚',
      '辛',
      '壬',
      '癸'
    ],
    diZhi = [
      '子',
      '丑',
      '寅',
      '卯',
      '辰',
      '巳',
      '午',
      '未',
      '申',
      '酉',
      '戌',
      '亥'
    ]

  // 公历转农历函数
  function sloarToLunar(sy, sm, sd) {
    // 输入的月份减1处理
    sm -= 1

    // 计算与公历基准的相差天数
    // Date.UTC()返回的是距离公历1970年1月1日的毫秒数,传入的月份需要减1
    let daySpan =
      (Date.UTC(sy, sm, sd) - Date.UTC(1949, 0, 29)) /
        (24 * 60 * 60 * 1000) +
      1
    let ly, lm, ld
    // 确定输出的农历年份
    for (let j = 0; j < lunarYearArr.length; j++) {
      daySpan -= lunarYearDays(lunarYearArr[j])
      if (daySpan <= 0) {
        ly = 1949 + j
        // 获取农历年份确定后的剩余天数
        daySpan += lunarYearDays(lunarYearArr[j])
        break
      }
    }

    // 确定输出的农历月份
    for (
      let k = 0;
      k < lunarYearMonths(lunarYearArr[ly - 1949]).length;
      k++
    ) {
      daySpan -= lunarYearMonths(lunarYearArr[ly - 1949])[k]
      if (daySpan <= 0) {
        // 有闰月时，月份的数组长度会变成13，因此，当闰月月份小于等于k时，lm不需要加1
        if (
          hasLeapMonth(lunarYearArr[ly - 1949]) &&
          hasLeapMonth(lunarYearArr[ly - 1949]) <= k
        ) {
          if (hasLeapMonth(lunarYearArr[ly - 1949]) < k) {
            lm = k
          } else if (
            hasLeapMonth(lunarYearArr[ly - 1949]) === k
          ) {
            lm = '闰' + k
          } else {
            lm = k + 1
          }
        } else {
          lm = k + 1
        }
        // 获取农历月份确定后的剩余天数
        daySpan += lunarYearMonths(lunarYearArr[ly - 1949])[
          k
        ]
        break
      }
    }

    // 确定输出农历哪一天
    ld = daySpan

    // 将计算出来的农历月份转换成汉字月份，闰月需要在前面加上闰字
    if (
      hasLeapMonth(lunarYearArr[ly - 1949]) &&
      typeof lm === 'string' &&
      lm.indexOf('闰') > -1
    ) {
      lm = `闰${lunarMonth[/\d/.exec(lm) - 1]}`
    } else {
      lm = lunarMonth[lm - 1]
    }

    // 将计算出来的农历年份转换为天干地支年
    ly = getTianGan(ly) + getDiZhi(ly)

    // 将计算出来的农历天数转换成汉字
    if (ld < 11) {
      ld = `${lunarDay[10]}${lunarDay[ld - 1]}`
    } else if (ld > 10 && ld < 20) {
      ld = `${lunarDay[9]}${lunarDay[ld - 11]}`
    } else if (ld === 20) {
      ld = `${lunarDay[1]}${lunarDay[9]}`
    } else if (ld > 20 && ld < 30) {
      ld = `${lunarDay[11]}${lunarDay[ld - 21]}`
    } else if (ld === 30) {
      ld = `${lunarDay[2]}${lunarDay[9]}`
    }

    return {
      lunarYear: ly,
      lunarMonth: lm,
      lunarDay: ld
    }
  }

  // 计算农历年是否有闰月，参数为存储农历年的16进制
  // 农历年份信息用16进制存储，其中16进制的最后1位可以用于判断是否有闰月
  function hasLeapMonth(ly) {
    // 获取16进制的最后1位，需要用到&与运算符
    if (ly & 0xf) {
      return ly & 0xf
    } else {
      return false
    }
  }

  // 如果有闰月，计算农历闰月天数，参数为存储农历年的16进制
  // 农历年份信息用16进制存储，其中16进制的第1位（0x除外）可以用于表示闰月是大月还是小月
  function leapMonthDays(ly) {
    if (hasLeapMonth(ly)) {
      // 获取16进制的第1位（0x除外）
      return ly & 0xf0000 ? 30 : 29
    } else {
      return 0
    }
  }

  // 计算农历一年的总天数，参数为存储农历年的16进制
  // 农历年份信息用16进制存储，其中16进制的第2-4位（0x除外）可以用于表示正常月是大月还是小月
  function lunarYearDays(ly) {
    let totalDays = 0

    // 获取正常月的天数，并累加
    // 获取16进制的第2-4位，需要用到>>移位运算符
    for (let i = 0x8000; i > 0x8; i >>= 1) {
      let monthDays = ly & i ? 30 : 29
      totalDays += monthDays
    }
    // 如果有闰月，需要把闰月的天数加上
    if (hasLeapMonth(ly)) {
      totalDays += leapMonthDays(ly)
    }

    return totalDays
  }

  // 获取农历每个月的天数
  // 参数需传入16进制数值
  function lunarYearMonths(ly) {
    let monthArr = []

    // 获取正常月的天数，并添加到monthArr数组中
    // 获取16进制的第2-4位，需要用到>>移位运算符
    for (let i = 0x8000; i > 0x8; i >>= 1) {
      monthArr.push(ly & i ? 30 : 29)
    }
    // 如果有闰月，需要把闰月的天数加上
    if (hasLeapMonth(ly)) {
      monthArr.splice(
        hasLeapMonth(ly),
        0,
        leapMonthDays(ly)
      )
    }

    return monthArr
  }

  // 将农历年转换为天干，参数为农历年
  function getTianGan(ly) {
    let tianGanKey = (ly - 3) % 10
    if (tianGanKey === 0) tianGanKey = 10
    return tianGan[tianGanKey - 1]
  }

  // 将农历年转换为地支，参数为农历年
  function getDiZhi(ly) {
    let diZhiKey = (ly - 3) % 12
    if (diZhiKey === 0) diZhiKey = 12
    return diZhi[diZhiKey - 1]
  }
  return sloarToLunar(sy, sm, sd)
}

//校验数组
export const ruleMaker = (arg = '不能为空') => {
  if (typeof arg === 'string')
    return [{ required: true, message: arg }]
  else {
    const {
      name = '',
      type = 'input',
      required = true,
      field = {},
      pattern,
      valueType
    } = arg
    const ruleArr = []
    const requiredRule = {
      required,
      message:
        type === 'input'
          ? `${name}不能为空`
          : `请选择${name}`
    }
    ruleArr.push(requiredRule)
    if (Object.keys(field).length) {
      const { min, max } = field
      const fieldRule = {
        min,
        max,
        message:
          min === max
            ? `${name}位数应为${max}个`
            : `${name}位数应当在${min}到${max}个之间`
      }
      ruleArr.push(fieldRule)
    }
    if (pattern) {
      const isRegExp = value =>
        Object.prototype.toString.call(value) ===
        '[object RegExp]'
      const patternRule = {
        pattern: isRegExp(pattern) ? pattern : pattern.reg,
        message: isRegExp(pattern)
          ? `${name}格式不正确`
          : pattern.message
      }
      ruleArr.push(patternRule)
    }
    if (valueType) {
      const valueTypeRule = {
        type: valueType,
        message: `${name}格式不正确`
      }
      ruleArr.push(valueTypeRule)
    }
    return ruleArr
  }
}

//格式化数组中的时间为年月日
export const formatterListTime = (
  list = [],
  time = 'publishTime'
) => {
  return list.map(item => {
    const [year, month, day] = dayjs(item[time])
      .format('YYYY-MM-DD')
      .split('-')
    return {
      ...item,
      year,
      month,
      day
    }
  })
}

//json转对象
export const JSONtoObject = (json = '') => {
  if (
    typeof json === 'string' &&
    json.startsWith('{') &&
    json.endsWith('}')
  )
    return JSON.parse(json)
  else return {}
}

//json转数组
export const JSONtoArray = (json = '') => {
  if (
    typeof json === 'string' &&
    json.startsWith('[') &&
    json.endsWith(']')
  )
    return JSON.parse(json)
  else return []
}

// 防抖
export const debounce = (fn, delay) => {
  let timeout = null
  return function () {
    let context = this
    let args = arguments
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

// 节流
export const throttle = (fn, delay) => {
  let timer = null
  return function () {
    let context = this
    let args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        fn.apply(context, args)
        timer = null
      }, delay)
    }
  }
}

// 返回
export const detailGoBack = () => {
  const route = router.app.$route
  const query = {
    id: route.query.id,
    cid: route.query.cid,
    source: route.query.source
  }
  router.push({
    path: route.path,
    query
  })
}

//页面跳转
export const routerLink = (
  routeParams = {},
  fn = 'push'
) => {
  if (!routeParams.path) return
  if (
    routeParams.path.includes('http') ||
    routeParams.path.includes('https')
  )
    window.open(routeParams.path, '_blank')
  else router[fn](routeParams)
}

// 哀悼模式设置
export const mourningSet = {
  getHTMLelement() {
    const [dom] = document.getElementsByTagName('html')
    return dom
  },
  add() {
    this.getHTMLelement().setAttribute('class', 'mourning')
  },
  remove(path = '') {
    const homeList = [
      '/home',
      '/temple',
      '/news',
      'promote',
      'college',
      'charity',
      'society',
      'hospital',
      'promote',
      'regulations'
    ]
    if (homeList.includes(path)) return
    this.getHTMLelement().removeAttribute('class')
  }
}

// 民族数组
export const nationData = [
  {
    label: '汉族',
    value: '1'
  },
  {
    label: '壮族',
    value: '2'
  },
  {
    label: '满族',
    value: '3'
  },
  {
    label: '回族',
    value: '4'
  },
  {
    label: '苗族',
    value: '5'
  },
  {
    label: '维吾尔族',
    value: '6'
  },
  {
    label: '土家族',
    value: '7'
  },
  {
    label: '彝族',
    value: '8'
  },
  {
    label: '蒙古族',
    value: '9'
  },
  {
    label: '藏族',
    value: '10'
  },
  {
    label: '布依族',
    value: '11'
  },
  {
    label: '侗族',
    value: '12'
  },
  {
    label: '瑶族',
    value: '13'
  },
  {
    label: '朝鲜族',
    value: '14'
  },
  {
    label: '白族',
    value: '15'
  },
  {
    label: '哈尼族',
    value: '16'
  },
  {
    label: '哈萨克族',
    value: '17'
  },
  {
    label: '黎族',
    value: '18'
  },
  {
    label: '傣族',
    value: '19'
  },
  {
    label: '畲族',
    value: '20'
  },
  {
    label: '傈僳族',
    value: '21'
  },
  {
    label: '仡佬族',
    value: '22'
  },
  {
    label: '东乡族',
    value: '23'
  },
  {
    label: '高山族',
    value: '24'
  },
  {
    label: '拉祜族',
    value: '25'
  },
  {
    label: '水族',
    value: '26'
  },
  {
    label: '佤族',
    value: '27'
  },
  {
    label: '纳西族',
    value: '28'
  },
  {
    label: '羌族',
    value: '29'
  },
  {
    label: '土族',
    value: '30'
  },
  {
    label: '仫佬族',
    value: '31'
  },
  {
    label: '锡伯族',
    value: '32'
  },
  {
    label: '柯尔克孜族',
    value: '33'
  },
  {
    label: '达斡尔族',
    value: '34'
  },
  {
    label: '景颇族',
    value: '35'
  },
  {
    label: '毛南族',
    value: '36'
  },
  {
    label: '撒拉族',
    value: '37'
  },
  {
    label: '布朗族',
    value: '38'
  },
  {
    label: '塔吉克族',
    value: '39'
  },
  {
    label: '阿昌族',
    value: '40'
  },
  {
    label: '普米族',
    value: '41'
  },
  {
    label: '鄂温克族',
    value: '42'
  },
  {
    label: '怒族',
    value: '43'
  },
  {
    label: '京族',
    value: '44'
  },
  {
    label: '基诺族',
    value: '45'
  },
  {
    label: '德昂族',
    value: '46'
  },
  {
    label: '保安族',
    value: '47'
  },
  {
    label: '俄罗斯族',
    value: '48'
  },
  {
    id: 49,
    label: '裕固族',
    value: '49'
  },
  {
    label: '乌孜别克族',
    value: '50'
  },
  {
    label: '门巴族',
    value: '51'
  },
  {
    label: '鄂伦春族',
    value: '52'
  },
  {
    label: '独龙族',
    value: '53'
  },
  {
    label: '塔塔尔族',
    value: '54'
  },
  {
    label: '赫哲族',
    value: '55'
  },
  {
    label: '珞巴族',
    value: '56'
  }
]

// 国家数组
const geolocation = [
  ['AO', '安哥拉'],
  ['AF', '阿富汗'],
  ['AL', '阿尔巴尼亚'],
  ['DZ', '阿尔及利亚'],
  ['AD', '安道尔共和国'],
  ['AI', '安圭拉岛'],
  ['AG', '安提瓜和巴布达'],
  ['AR', '阿根廷'],
  ['AM', '亚美尼亚'],
  ['AU', '澳大利亚'],
  ['AT', '奥地利'],
  ['AZ', '阿塞拜疆'],
  ['BS', '巴哈马'],
  ['BH', '巴林'],
  ['BD', '孟加拉国'],
  ['BB', '巴巴多斯'],
  ['BY', '白俄罗斯'],
  ['BE', '比利时'],
  ['BZ', '伯利兹'],
  ['BJ', '贝宁'],
  ['BM', '百慕大群岛'],
  ['BO', '玻利维亚'],
  ['BW', '博茨瓦纳'],
  ['BR', '巴西'],
  ['BN', '文莱'],
  ['BG', '保加利亚'],
  ['BF', '布基纳法索'],
  ['MM', '缅甸'],
  ['BI', '布隆迪'],
  ['CM', '喀麦隆'],
  ['CA', '加拿大'],
  ['CF', '中非共和国'],
  ['TD', '乍得'],
  ['CL', '智利'],
  ['CN', '中国'],
  ['CO', '哥伦比亚'],
  ['CG', '刚果'],
  ['CK', '库克群岛'],
  ['CR', '哥斯达黎加'],
  ['CU', '古巴'],
  ['CY', '塞浦路斯'],
  ['CZ', '捷克'],
  ['DK', '丹麦'],
  ['DJ', '吉布提'],
  ['DO', '多米尼加共和国'],
  ['EC', '厄瓜多尔'],
  ['EG', '埃及'],
  ['SV', '萨尔瓦多'],
  ['EE', '爱沙尼亚'],
  ['ET', '埃塞俄比亚'],
  ['FJ', '斐济'],
  ['FI', '芬兰'],
  ['FR', '法国'],
  ['GF', '法属圭亚那'],
  ['GA', '加蓬'],
  ['GM', '冈比亚'],
  ['GE', '格鲁吉亚'],
  ['DE', '德国'],
  ['GH', '加纳'],
  ['GI', '直布罗陀'],
  ['GR', '希腊'],
  ['GD', '格林纳达'],
  ['GU', '关岛'],
  ['GT', '危地马拉'],
  ['GN', '几内亚'],
  ['GY', '圭亚那'],
  ['HT', '海地'],
  ['HN', '洪都拉斯'],
  ['HK', '香港'],
  ['HU', '匈牙利'],
  ['IS', '冰岛'],
  ['IN', '印度'],
  ['ID', '印度尼西亚'],
  ['IR', '伊朗'],
  ['IQ', '伊拉克'],
  ['IE', '爱尔兰'],
  ['IL', '以色列'],
  ['IT', '意大利'],
  ['JM', '牙买加'],
  ['JP', '日本'],
  ['JO', '约旦'],
  ['KH', '柬埔寨'],
  ['KZ', '哈萨克斯坦'],
  ['KE', '肯尼亚'],
  ['KR', '韩国'],
  ['KW', '科威特'],
  ['KG', '吉尔吉斯坦'],
  ['LA', '老挝'],
  ['LV', '拉脱维亚'],
  ['LB', '黎巴嫩'],
  ['LS', '莱索托'],
  ['LR', '利比里亚'],
  ['LY', '利比亚'],
  ['LI', '列支敦士登'],
  ['LT', '立陶宛'],
  ['LU', '卢森堡'],
  ['MO', '澳门'],
  ['MG', '马达加斯加'],
  ['MW', '马拉维'],
  ['MY', '马来西亚'],
  ['MV', '马尔代夫'],
  ['ML', '马里'],
  ['MT', '马耳他'],
  ['MU', '毛里求斯'],
  ['MX', '墨西哥'],
  ['MD', '摩尔多瓦'],
  ['MC', '摩纳哥'],
  ['MN', '蒙古'],
  ['MS', '蒙特塞拉特岛'],
  ['MA', '摩洛哥'],
  ['MZ', '莫桑比克'],
  ['NA', '纳米比亚'],
  ['NR', '瑙鲁'],
  ['NP', '尼泊尔'],
  ['NL', '荷兰'],
  ['NZ', '新西兰'],
  ['NI', '尼加拉瓜'],
  ['NE', '尼日尔'],
  ['NG', '尼日利亚'],
  ['KP', '朝鲜'],
  ['NO', '挪威'],
  ['OM', '阿曼'],
  ['PK', '巴基斯坦'],
  ['PA', '巴拿马'],
  ['PG', '巴布亚新几内亚'],
  ['PY', '巴拉圭'],
  ['PE', '秘鲁'],
  ['PH', '菲律宾'],
  ['PL', '波兰'],
  ['PF', '法属玻利尼西亚'],
  ['PT', '葡萄牙'],
  ['PR', '波多黎各'],
  ['QA', '卡塔尔'],
  ['RO', '罗马尼亚'],
  ['RU', '俄罗斯'],
  ['LC', '圣卢西亚'],
  ['VC', '圣文森特岛'],
  ['SM', '圣马力诺'],
  ['ST', '圣多美和普林西比'],
  ['SA', '沙特阿拉伯'],
  ['SN', '塞内加尔'],
  ['SC', '塞舌尔'],
  ['SL', '塞拉利昂'],
  ['SG', '新加坡'],
  ['SK', '斯洛伐克'],
  ['SI', '斯洛文尼亚'],
  ['SB', '所罗门群岛'],
  ['SO', '索马里'],
  ['ZA', '南非'],
  ['ES', '西班牙'],
  ['LK', '斯里兰卡'],
  ['SD', '苏丹'],
  ['SR', '苏里南'],
  ['SZ', '斯威士兰'],
  ['SE', '瑞典'],
  ['CH', '瑞士'],
  ['SY', '叙利亚'],
  ['TW', '台湾省'],
  ['TJ', '塔吉克斯坦'],
  ['TZ', '坦桑尼亚'],
  ['TH', '泰国'],
  ['TG', '多哥'],
  ['TO', '汤加'],
  ['TT', '特立尼达和多巴哥'],
  ['TN', '突尼斯'],
  ['TR', '土耳其'],
  ['TM', '土库曼斯坦'],
  ['UG', '乌干达'],
  ['UA', '乌克兰'],
  ['AE', '阿拉伯联合酋长国'],
  ['GB', '英国'],
  ['US', '美国'],
  ['UY', '乌拉圭'],
  ['UZ', '乌兹别克斯坦'],
  ['VE', '委内瑞拉'],
  ['VN', '越南'],
  ['YE', '也门'],
  ['YU', '南斯拉夫'],
  ['ZW', '津巴布韦'],
  ['ZR', '扎伊尔'],
  ['ZM', '赞比亚']
]
export const countryData = geolocation.map(item => {
  return {
    label: item[1],
    value: item[1],
    children: null
  }
})

// 通用正则
export const Rules = {
  mobile: ruleMaker({
    name: '手机号',
    field: { min: 11, max: 11 },
    pattern: /^1[3456789]\d{9}$/
  })
}

// 牌位
export const futuerLife = {
  one: {
    name: '',
    date1: '',
    date2: ''
  },
  two: {
    name: '',
    date1: '',
    date2: ''
  },
  three: {
    name: '',
    date1: '',
    date2: ''
  },
  four: {
    name: '',
    date1: '',
    date2: ''
  },
  five: {
    name: '',
    date1: '',
    date2: ''
  },
  six: {
    name: '',
    date1: '',
    date2: ''
  },
  seven: {
    name: '',
    date1: '',
    date2: ''
  },
  eight: {
    name: '',
    date1: '',
    date2: ''
  },
  nine: {
    name: '',
    date1: '',
    date2: ''
  }
}

// 牌位表单校验
const futuerLifeNameRule = ruleMaker({
  name: '',
  pattern: {
    reg: /^[\u4e00-\u9fa50-9A-Za-z]+$/,
    message: '不能有符号'
  }
})
const defaultSelectRule = ruleMaker({ type: 'select' })
export const applyFormRules = {
  name: ruleMaker(),
  introduce: ruleMaker(),
  applyPerson: ruleMaker(),
  superabundanceType: defaultSelectRule,
  tabletType: defaultSelectRule,
  ...(() => {
    const rules = {}
    for (const ckey in futuerLife) {
      for (const pkey in futuerLife[ckey]) {
        if (pkey === 'name')
          rules[`futuerLife.${ckey}.${pkey}`] =
            futuerLifeNameRule
        if (pkey === 'date1' || pkey === 'date2')
          rules[`futuerLife.${ckey}.${pkey}`] =
            defaultSelectRule
      }
    }
    return rules
  })()
}
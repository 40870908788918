<template>
  <div class="my-bread SourceHanSerifCN-Regular">
    <span class="now-position"
      >您现在的位置<i class="el-icon-arrow-right"></i
    ></span>
    <el-breadcrumb separator="|">
      <el-breadcrumb-item :to="{ path: '/home' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item
        v-for="(
          { meta: { name }, path }, index
        ) of routeList"
        :class="path === $route.path ? 'is-active' : ''"
        :to="{ path }"
        :key="index"
      >
        {{ name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'MyBread', //面包屑组件
  data() {
    return {
      routeList: []
    }
  },
  methods: {
    init() {
      this.routeList = [...this.$route.matched].filter(
        ({ meta: { noAddInRouteList } }) =>
          !noAddInRouteList
      )
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    $route() {
      this.init()
    }
  }
}
</script>

<style lang="less" scoped>
.my-bread {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 1600px;
  margin: 0 auto;
  font-size: 16px;
  padding: 17px 0 26px 0;
  color: @main-color-BFB2AA;
  .now-position {
    display: flex;
    align-items: center;
    i {
      font-weight: bold;
      margin: 0 13px;
      font-size: 12px;
    }
  }
  :deep(.el-breadcrumb) {
    display: flex;
    align-items: center;
    .el-breadcrumb__item {
      .el-breadcrumb__inner {
        font-size: 16px;
        color: @main-color-BFB2AA;
        &.is-link {
          font-weight: normal;
        }
      }
      .el-breadcrumb__separator {
        font-weight: normal;
        position: relative;
        top: -2px;
        margin: 0 13px;
      }
      &.is-active {
        .el-breadcrumb__inner {
          color: @main-color-A45E44;
        }
      }
    }
  }
}
</style>
